if(document.querySelector(".ce_rsce_stephan_video")){
    document.querySelectorAll(".ce_rsce_stephan_video").forEach(element => {
        if(element.querySelector(".play-button")){
            element.querySelector(".play-button").addEventListener("click", () => {
                element.querySelector(".video-overlay").classList.add("active");
            });
            element.querySelector(".video-overlay .close").addEventListener("click", event => {
                event.stopPropagation();
                element.querySelector(".video-overlay").classList.remove("active");
                element.querySelector(".video-overlay").querySelector("video").pause();
            });
        }
    });
}