var apiKey = "AIzaSyAmz71RDkeWfd5pbSNaOSJf6czVuET7_HE";
var setCookie = true;
var cookieExpire = 2*60*60*1000; // 2 Stunden Gültigkeit

window.googleMapsScriptLoaded = function () {
    var event = new Event('googleMapsScriptLoaded', {"bubbles": true, "cancelable": false})
    window.dispatchEvent(event);
};

(function (window) {
    var windowHeight = window.innerHeight,
        windowScrollTop = 0,
        debounce = function (delay, fn) {
            var timer = null;
            return function () {
                var context = this, args = arguments;
                clearTimeout(timer);
                timer = setTimeout(function () { fn.apply(context, args); }, delay);
            };
        },
        apiScriptLoaded = false,
        apiScriptLoading = false,
        containers = [],
        init = function (callback) {
            windowScrollTop = window.scrollTop;
            containers.forEach(container => {
                if (container.getBoundingClientRect().top - windowScrollTop > windowHeight * 1)
                    return true;
                if (!apiScriptLoaded && !apiScriptLoading) {
                    var script = document.createElement("script");
                    script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&callback=googleMapsScriptLoaded&key=' + apiKey;
                    document.body.appendChild(script);
                    apiScriptLoading = true;
                }
                if (!apiScriptLoaded) return true;
                var map = new google.maps.Map(container, {
                    zoom: 15
                });
                if (container.data.callback) {
                    container.data.callback(container, map);
                }
            });
        };
    window.addEventListener('googleMapsScriptLoaded', () => {
        apiScriptLoaded = true;
        init();
    });
    HTMLDivElement.prototype.lazyLoadGoogleMaps = function (options) {
        options = extend({
            api_key: false,
            callback: false,
        }, options);
        containers.push(this);
        this.data = options;
        init();
        this.debounce = debounce;
        return this;
    };
})(window);

function extend(out) {
    out = out || {};
    for (var i = 1; i < arguments.length; i++) {
        if (!arguments[i])
            continue;
        for (var key in arguments[i]) {
            if (arguments[i].hasOwnProperty(key))
                out[key] = arguments[i][key];
        }
    }
    return out;
};


document.querySelectorAll(".map-container").forEach(mapContainer => {
    var button = mapContainer.querySelector("button.load-map");
    button.addEventListener("click", () => {
        var map = mapContainer.querySelector(".map");
        button.parentElement.parentElement.style.display = "none";
        if(setCookie){
            const d = new Date();
            d.setTime(d.getTime() + cookieExpire);
            document.cookie = "maps=true; expires=" + d.toUTCString() + "; path=/";
        }
        var maps = [];
        var gMaps = map.lazyLoadGoogleMaps({
            callback: function (container, googleMap) {
                var centerPosition = new google.maps.LatLng(map.dataset.latitudecenter, map.dataset.longitudecenter);
                var position = new google.maps.LatLng(map.dataset.latitude, map.dataset.longitude);
                googleMap.setOptions({
                    zoom: parseInt(map.dataset.zoom),
                    center: centerPosition,
                    scrollwheel: false,
					styles: [
						{
							"featureType": "administrative",
							"elementType": "labels.text.fill",
							"stylers": [
								{
									"color": "#444444"
								}
							]
						},
						{
							"featureType": "administrative.country",
							"elementType": "all",
							"stylers": [
								{
									"visibility": "off"
								}
							]
						},
						{
							"featureType": "administrative.country",
							"elementType": "geometry",
							"stylers": [
								{
									"visibility": "off"
								}
							]
						},
						{
							"featureType": "administrative.country",
							"elementType": "labels",
							"stylers": [
								{
									"visibility": "off"
								}
							]
						},
						{
							"featureType": "administrative.province",
							"elementType": "all",
							"stylers": [
								{
									"visibility": "off"
								}
							]
						},
						{
							"featureType": "administrative.province",
							"elementType": "labels",
							"stylers": [
								{
									"visibility": "on"
								}
							]
						},
						{
							"featureType": "administrative.province",
							"elementType": "labels.text",
							"stylers": [
								{
									"visibility": "off"
								}
							]
						},
						{
							"featureType": "administrative.locality",
							"elementType": "all",
							"stylers": [
								{
									"visibility": "on"
								}
							]
						},
						{
							"featureType": "administrative.locality",
							"elementType": "labels",
							"stylers": [
								{
									"visibility": "on"
								}
							]
						},
						{
							"featureType": "administrative.neighborhood",
							"elementType": "all",
							"stylers": [
								{
									"visibility": "off"
								}
							]
						},
						{
							"featureType": "administrative.neighborhood",
							"elementType": "geometry",
							"stylers": [
								{
									"visibility": "on"
								}
							]
						},
						{
							"featureType": "administrative.neighborhood",
							"elementType": "labels",
							"stylers": [
								{
									"visibility": "off"
								},
								{
									"saturation": "-1"
								}
							]
						},
						{
							"featureType": "administrative.land_parcel",
							"elementType": "all",
							"stylers": [
								{
									"visibility": "on"
								}
							]
						},
						{
							"featureType": "administrative.land_parcel",
							"elementType": "labels",
							"stylers": [
								{
									"visibility": "off"
								}
							]
						},
						{
							"featureType": "landscape",
							"elementType": "all",
							"stylers": [
								{
									"color": "#b0b0b0"
								},
								{
									"visibility": "on"
								}
							]
						},
						{
							"featureType": "landscape",
							"elementType": "geometry",
							"stylers": [
								{
									"visibility": "on"
								}
							]
						},
						{
							"featureType": "landscape",
							"elementType": "labels",
							"stylers": [
								{
									"visibility": "on"
								},
								{
									"saturation": "0"
								}
							]
						},
						{
							"featureType": "poi",
							"elementType": "all",
							"stylers": [
								{
									"visibility": "off"
								}
							]
						},
						{
							"featureType": "road",
							"elementType": "all",
							"stylers": [
								{
									"saturation": -100
								},
								{
									"lightness": 25
								},
								{
									"visibility": "on"
								}
							]
						},
						{
							"featureType": "road",
							"elementType": "geometry.fill",
							"stylers": [
								{
									"color": "#c6c6c6"
								}
							]
						},
						{
							"featureType": "road",
							"elementType": "labels",
							"stylers": [
								{
									"visibility": "off"
								}
							]
						},
						{
							"featureType": "road",
							"elementType": "labels.text",
							"stylers": [
								{
									"visibility": "on"
								}
							]
						},
						{
							"featureType": "road.highway",
							"elementType": "all",
							"stylers": [
								{
									"visibility": "simplified"
								}
							]
						},
						{
							"featureType": "road.highway",
							"elementType": "geometry",
							"stylers": [
								{
									"visibility": "off"
								}
							]
						},
						{
							"featureType": "road.highway",
							"elementType": "geometry.fill",
							"stylers": [
								{
									"color": "#c6c6c6"
								}
							]
						},
						{
							"featureType": "road.highway",
							"elementType": "labels",
							"stylers": [
								{
									"visibility": "on"
								}
							]
						},
						{
							"featureType": "road.arterial",
							"elementType": "labels.icon",
							"stylers": [
								{
									"visibility": "on"
								}
							]
						},
						{
							"featureType": "road.arterial",
							"elementType": "geometry.fill",
							"stylers": [
								{
									"color": "#c6c6c6"
								}
							]
						},
						{
							"featureType": "transit",
							"elementType": "all",
							"stylers": [
								{
									"visibility": "off"
								}
							]
						},
						{
							"featureType": "water",
							"elementType": "all",
							"stylers": [
								{
									"color": "#a2a2a2"
								},
								{
									"visibility": "on"
								}
							]
						}
					]
                });
				var icon = {
					url: "/files/themes/baufinanz-stephan/images/icons/standort-white.svg",
					scaledSize: new google.maps.Size(30, 40)
				}
                new google.maps.Marker({
                    position: position,
                    map: googleMap,
					icon: icon
                });
                googleMap.center = centerPosition;
                maps.push(googleMap);
                var center = function () {
                    googleMap.center = googleMap.getCenter();
                };
                google.maps.event.addListener(googleMap, "dragend", center);
                google.maps.event.addListener(googleMap, "zoom_changed", center);
                google.maps.event.addListenerOnce(googleMap, "idle", function () {
                    container.classList.add("is-loaded");
                });
            }
        });
        window.addEventListener("resize", gMaps.debounce(1E3, function () {
            maps.forEach(() => {
                this.setCenter(this.dataset.center);
            });
        }));
    });
})

if(setCookie && getCookie("maps") == "true"){
    document.querySelectorAll(".map-container").forEach(container => {
        var button = container.querySelector("button.load-map");
        var event = new Event('click', {"bubbles": true, "cancelable": false});
        button.dispatchEvent(event);
    });
}

function getCookie(cname){
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}